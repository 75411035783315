
import './App.css';
import React,{useState,useEffect} from "react";
import BlogCategory from './blogCategory.json';
import Axios from 'axios';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { MDBBreadcrumb, MDBBreadcrumbItem } from 'mdb-react-ui-kit';
import { Link, useParams } from "react-router-dom";
import {  scrollToTop } from "./App";
import blog from "./blog.json"
import MetaTags from 'react-meta-tags';
function Apply() {

    const [blogDetail,setBlogDetail] = useState(null)
    const param = useParams();
    useEffect(() => {
    
        scrollToTop();
        console.log('==================', param.name)
        blog.map(mb => {
            mb.Blog.map(lb => {
                if(lb.bloglink?.replaceAll(" ", "-") === param.name){
                  setBlogDetail(lb)
                }
            })
        })

     
      }, [])

      useEffect(() => {
        console.log("======== blogDetail",blogDetail)
      }, [blogDetail])


    const url = "https://dummyjson.com/products"
    const [data, setData] = useState({
      name : "",
      email : "",
      websie : "" ,
      message : ""
    })

    function submit(e) {
      e.preventDefault();
      Axios.post(
        url, {
          name: data.name,
          email: data.email,
          website: data.companyName,
          message : data.message
        })
            .then(res => {
              console.log(res.data)
            })
    }

    function handle(e) {
      const newdata = { ...data }
      newdata[e.target.id] = e.target.value
      setData(newdata)
      console.log(newdata)
    }
  return (


    
   
    <div className="App BlogDetail banner-sec ">
     <MetaTags>
            
            <meta name="description" content={blogDetail?.description} />
            <meta name="keyword" content={blogDetail?.keyword} />
            <meta property="og:title" content={blogDetail?.ogtitle} />
            <link rel="canonical" href={`https://www.technopus.com/${blogDetail?.bloglink.replaceAll(" ","-")}`} />
            <title>{`${blogDetail?.title}`}</title>
            
      </MetaTags>

    <MDBBreadcrumb className='breadcrumb container'>
          <MDBBreadcrumbItem>
          <Link to="/" replace={false} className='text-black' ><b>Home</b></Link>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem>
          <Link to="/blog" replace={false} className='text-black'><b>Blog</b></Link>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem active className='text-black '>
            <a>{blogDetail?.title.replaceAll(" ","-")}</a>
          </MDBBreadcrumbItem>
        </MDBBreadcrumb>
    <div className="container">

        

    <div className='row'>
        <div className='col-lg-8'>
            <h3 className='blog-title'>{blogDetail?.title?? ""}</h3>
            <ul>
                <li>{blogDetail?.Skills?? ""}</li>
                <li>{blogDetail?.Skills?? ""}</li>
            </ul>
            <img src={blogDetail?.images?? ""} className="mb-5" alt={"blog"} />
            
             {blogDetail?.allContent?.map(ps=>  {
                return(
                    <p className='blog-all-content'>{ReactHtmlParser(ps)}</p>
                )
             })}       
            
            
           
            <h3 className='blog-subtitle'>Leave your thought</h3>
            <form className='blog-detail-form' onSubmit={(e)=> submit(e)}>
                <div className='input-row'>
                <input id="name"  placeholder="Name"   onChange={(e) => handle(e)}  value={data.name} required type="text" pattern="[A-Z]{}"/>
                <input id="email"  placeholder="Email" onChange={(e) => handle(e)} value={data.email} required type="email"  />
                </div>
                <input id="website" placeholder="Website" onChange={(e) => handle(e)} value={data.website} required type="text" pattern="[A-Z]{}" />
                <textarea onChange={(e) => handle(e)} id="message" value={data.message} placeholder="Your message" required type="text"></textarea>
                <button type="submit">submit</button>
            </form>
        </div>
        <div className='col-lg-4 blog-detail-right'>
            <h3>CATEGORY</h3>
            <ul className='p-0 pb-4'>
                {BlogCategory.map( BlogCategory => {
                return(
                <li>       
                <ul className='p-0 category-row '>
                    <li>{BlogCategory.cetegory}</li>
                    <li>{BlogCategory.blogCount}</li>
                </ul>
                </li> 
                )})} 
            </ul>
            <h3>TAGS CLOUD</h3>
            <div className='tags pb-4'>   
                <div className='d-flex flex-wrap'>
                {blogDetail?.tag?.map(ps=>  {
                return(
                    <button>{ps}</button>
                )})} 
                </div>
            </div>
            
        </div>
    </div>
    
    </div>
   </div> 
    
  );
}

export default Apply;

