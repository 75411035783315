
import './App.css';
import React, { useState,useEffect } from "react";
import PrivacyPolicyList from './PrivacyPolicy.json'
import MetaTags from 'react-meta-tags';
import {  scrollToTop } from "./App";
function PrivacyPolicy() {
    useEffect(() => {
    
        scrollToTop();
     
      }, [])
  return (
    <>
        { PrivacyPolicyList.map( PrivacyPolicy => {
        return( 
    <div className="App PrivacyPolicy">
    <MetaTags>
            
            <meta name="description" content="We will not use or share your personal information with anyone except as described in this Privacy Policy. " />
            <meta name="keyword" content="Mobile App Development, Hire Laravel Developer, Hire Codeigniter, Developer, Hire Javascript Developer, app web developer, hire web developer, ui ux web developer, web design and web developer, web development services, Web Engineering, Mobility, Digital Marketing, Game Developing, Strategic Consulting, Web CMS And Portal Development, e-Commerce Development, social media marketing agency" />
            <meta property="og:title" content="Privacy-Policy | Technopus" />
            <link rel="canonical" href="https://www.technopus.com/privacy-policy" />
            <title>Technopus IT Services - Rajkot - Gujarat - INDIA | Privacy-Policy</title>
      </MetaTags>
     <section className='privacypolicy  banner-sec'>
        <div className='container'>
            <div className='row align-items-center privacy-benner'>
                <div className='col-md-6'>
                    <h1 className='lastword'>{PrivacyPolicy.title}</h1>
                    <p>{PrivacyPolicy.mainContent}</p>
                </div>
                <div className='col-md-6'>
                    <img src={PrivacyPolicy.image} alt={"privacy policy"} />
                </div>
            </div>
        </div>
     </section>

     <section className='privacy-policy-content'> 
        <div className='container'>
            <div className='Privacy-Policy-Content'>
                <div>
                    <h3>{PrivacyPolicy.subtitle1}</h3>
                    { PrivacyPolicy.content1.map( ps => { 
                    return(
                    <p>{ps}</p>
                    )})}
                </div>
                <div>
                <h3>{PrivacyPolicy.subtitle2}</h3>
                { PrivacyPolicy.content2.map( ps => { 
                    return(
                    <p>{ps}</p>
                    )})}
                </div>
                <div>
                <h3>{PrivacyPolicy.subtitle3}</h3>
                { PrivacyPolicy.content3.map( ps => { 
                    return(
                    <p>{ps}</p>
                    )})}
                </div>
                <div>
                <h3>{PrivacyPolicy.subtitle4}</h3>
                { PrivacyPolicy.content4.map( ps => { 
                    return(
                    <p>{ps}</p>
                    )})}
                </div>
                <div>
                <h3>{PrivacyPolicy.subtitle5}</h3>
                { PrivacyPolicy.content5.map( ps => { 
                    return(
                    <p>{ps}</p>
                    )})}
                </div>
                <div>
                <h3>{PrivacyPolicy.subtitle6}</h3>
                { PrivacyPolicy.content6.map( ps => { 
                    return(
                    <p>{ps}</p>
                    )})}
                </div>
                <div>
                <h3>{PrivacyPolicy.subtitle7}</h3>
                { PrivacyPolicy.content7.map( ps => { 
                    return(
                    <p>{ps}</p>
                    )})}
                </div>
            </div>
        </div>
     </section>
     

    </div>
    )})}
    </>
  );
}

export default PrivacyPolicy;

