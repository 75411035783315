import webengineering from './assets/webengineering.webp';
import webengineeringbg from './assets/webengineeringbg.webp';
// import { PopupButton } from '@typeform/embed-react'
import React, { useEffect } from "react";
import './App.css';
import 'react-tabs/style/react-tabs.css';
import Developeractivitycuate from './assets/Developeractivitycuate.svg';
import industries from './industries.json';
import keybenifit from './keybenifit.json';
import howitwork from './howitwork.json';
import HowWeWork from './assets/webengineering2.svg';
import MetaTags from 'react-meta-tags';
import {  scrollToTop } from "./App";
import { Link } from 'react-router-dom';
function WebappDevelopment() {
    useEffect(() => {
        scrollToTop();
      }, [])
  return (
    <div className="App WebEngineering">
       <MetaTags>
            <meta name="description" content="Technopus is a leading web app development company driving digital success. Unlock your online potential with our expert solutions." />
            <meta name="keyword" content="web development company, web application development company," />
            <meta property="og:title" content="Web Application Development Company | Technopus" />
            <link rel="canonical" href="https://www.technopus.com/web-app-development"/>
            <title>Technopus IT Services - Rajkot - Gujarat - INDIA | Web Application Development Company</title>
      </MetaTags>
      <section className='home-banner banner-sec Web-Engineering'>
        <div className='container'>
            <div className='row align-items-center'>
                {/* <div className='col-md-6'>
                    <div className='banner-img text-center'>
                    <img src={webengineering} className="main-banner" alt={"banner"} />
                    </div>
                </div> */}
                <div className='col-12'>
                    <div className='banner-content text-center'>
                        <h1>Top Web <span className='txt-with-bg'>App Development</span>  <br/>Services</h1>
                        <p>Technopus is a top <b>web app development</b> services with expertise in the web app development field. Our team of experts is all set to deliver a professional product for you with guaranteed satisfaction at an affordable rate. </p>
                        <Link to="/contact-us" className='quote'>Request A Quote</Link>
                    </div>
                </div>
            </div>
        </div>
      </section>
      <section className='DevelopmentService'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-md-6'>
                    <img src={ Developeractivitycuate } alt={"development activity "} />
                </div>
                <div className='col-md-6 sub-content'>
                    <h2>Web CMS & Portal Development Service</h2>
                    <p className='bold'>54% of All the World’s Websites are Powered by CMSs</p>
                    <p>CMS development service has become an utmost necessity for the organizations as everything depends on the effective data and information collection and usage in today’s knowledge intensive enterprise economies. CMSs and portals are the best tools for presenting and managing information efficaciously.</p>
                </div>
            </div>
        </div>
      </section>
      <section className='industriesserve'>
          <div className='container'>
               <h2 className='line-bottom'>Industries We <span className='lastword'>Serve</span></h2>
               <div className='row justify-content-between'>
                    {industries.map( industries => {
                    return(
                    <div className='indusries-card'>
                        <img src={ industries.icon } alt={"industries"} loading='lazy' />
                        <p>{industries.name}</p>
                    </div>
                    )})} 
               </div>
          </div>
      </section>
      <section className='HowWework '>
          <div className='container'>
              <div className='row align-items-center'>
                  
                        <div className='col-md-6 sub-content'>
                             <h2>How We work as Development Service Provider</h2>
                             <ul>
                                <li>We begin by analysing the specific client requisites and their inimitable requirements for the web development.</li>
                                <li>Our web app development experts leverage the most advanced tools and proven methodologies to shape bespoke solutions.</li>
                                <li>We deliver distinctly superior services and seamless user experience by building robust solutions.</li>
                             </ul>
                        </div>
                        <div className='col-md-6'>
                            <img src={ HowWeWork } alt={"how we work"}  loading='lazy' />
                        </div>
              </div>
          </div>
      </section>
      <section className='key-benifit'>
        <div className='container'>
            <h2 className='line-bottom'>Key Benefits Of <span className='lastword'>Choosing Us</span></h2>
            <div className='row'>
               {keybenifit.map( keybenifit => {
                return(
               <div className=' col-md-6'>
                    <div className='key-benifit-cad'>
                        <h5>{ keybenifit.head }</h5>
                        <p>{ keybenifit.about }</p>
                   </div>
               </div>
               )})} 
            </div>
        </div>
      </section>
      <section className='How-It-Work'>
          <div className='container'>
               <h2 className='line-bottom'>How It <span className='lastword'>Work?</span></h2> 
               {howitwork.map( howitwork => {
                return(
               <div className='row how-it-work-row align-items-center'>
                    <div className='col-md-6 text-center'>
                       <img src={ howitwork.image } alt={"how it work"} loading='lazy' />
                    </div>
                    <div className='col-md-6'>
                        <h3>{ howitwork.MainHead }</h3>
                        <h4>{ howitwork.SubHead }</h4>
                        <p>{ howitwork.text }</p>
                    </div>
               </div>
               )})}     
          </div>
      </section>
      <section className='Tell-Us-About-Your-Project'>
           <div className='container'>
              <h2 className='line-bottom'>Tell Us About Your <span className='lastword'>Project</span></h2>
              <div className='row align-items-center'>
                            <div className='col-md-6'>
                                <img src={ HowWeWork } alt={"how we work"} loading='lazy'/>
                            </div>
                            <div className='col-md-6'>
                                <h3>What will happen next?</h3>
                                <ul>
                                    <li>We’ll reach out to you within 24 hours.</li>
                                    <li>We’ll discuss your project and gather your requirements and business objectives, and develop a proposal accordingly.</li>
                                    <li>You can start 15-days risk-free trial with us.</li>
                                </ul>
                                
                                <Link to="/contact-us" className="quote">
                                    Contact Us
                                </Link>
                            </div>
              </div>
           </div>
      </section> 
    </div>
  );
}
export default WebappDevelopment;
