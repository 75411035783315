import './App.css';
import React, { useEffect } from "react";
import MetaTags from 'react-meta-tags';
// import { PopupButton } from '@typeform/embed-react'
import colaborotors from './assets/colaborotors.webp'
import leftrightcolaborotors from './assets/left-rigtcolaborotors.webp'
import leftcolaborotors from './assets/leftcolaborotors.webp'
import rightcolaborotors from './assets/rightcolaborotors.webp'
import AreaDetecter from './assets/AreaDetecter.webp'
import Portfolio from './portfolio';
import Marquee from "react-fast-marquee";
import arvrclient from './arvrclient.json'
import arvr from './assets/ARVR.webp'
import {  scrollToTop } from "./App";
import { Link } from 'react-router-dom';





function ARVR() {
  useEffect(() => {
    
    scrollToTop();
 
  }, [])

      
     
  return (
    <div className="App ARVR">
     <MetaTags>
            
            <meta name="description" content="Technopus is one of the top AR/VR app development.Transforming experiences, one virtual world at a time." />
            <meta name="keyword" content="ar vr app development, ar vr development company" />
            <meta property="og:title" content="AR VR App Development Company | Technopus" />
            <link rel="canonical" href="https://www.technopus.com/ar-vr-development" />
            <title>Technopus IT Services - Rajkot - Gujarat - INDIA | AR VR App Development Company</title>
            
      </MetaTags>
     
     <section className='ARVR-banner banner-sec '>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <h1>
                    Top AR VR App Development Services
                    </h1>
                    <p>
                    Reenvision the reality of people with Technopus. We provide immersive <b>AR VR App Development</b> Solutions that empower individuals. Let your users experience the magic of the AR/VR world. 
                    </p>
                    <Link to="/contact-us" className=" quote-arvr">
                        Request A Quote
                        </Link>
                </div>
                <div className='col-md-6'>
                    <img src={arvr} alt='banner-img' />
                </div>
            </div>
        </div>
        <div className='bannergredient1'></div>
        <div className='bannergredient2'></div>
        <div className='bannergredient3'></div>
        <div className='bannergredient4'></div>
     </section>

     <section className='Collaborators position-relative'>
        <div className='container'> 
          <h2 className='pt-5'>Our Collaborators and Allies</h2>
          <div className='text-center'>
          <img src={colaborotors} alt='colaborotors' />
          </div>
          <div className='row colaborotors-detail align-items-center'>
              <div className='col-md-6 '>
                <div className='position-relative width-fit-content overflow-hidden'>
                <img src={leftrightcolaborotors} alt='virtual-reality' />
                <div className='leftcolaborotors position-absolute'>
                    <img src={leftcolaborotors} alt='virtual-reality' />
                </div>
                <div className='rightcolaborotors position-absolute'>
                <img src={rightcolaborotors} alt='virtual-reality' />
                </div>
                </div>
              </div>
              <div className='col-md-6'>
                    <h2 className='text-start p-0'>Enter a New Reality with Our VR Solutions</h2>
                    <p>Unlock Limitless Possibilities with Arrcus VR Technology - Explore, Learn, and Create in a New Reality.</p>
                    <a href='https://www.technopus.com/future-of-virtual-reality-market-trends-and-challenges' target='_blank'>
                    <button  className=" quote-arvr">
                    Know More
                    </button>
                    </a>
              </div>
          </div>
          <div class="bannergredient1"></div>
        </div>

     </section>

     <section className='Collaborators'>
        <div className='container'> 
         
          <div className='row colaborotors-detail align-items-center'>
                <div className='col-md-6'>
                    <h2 className='text-start p-0'>Enter a New Reality with Our AR Solutions</h2>
                    <p>Unlock Limitless Possibilities with Arrcus VR Technology - Explore, Learn, and Create in a New Reality.</p>
                    <a  href='https://www.technopus.com/future-of-augmented-reality-trends' target='_blank'>
                    <button  className=" quote-arvr">
                    Know More
                    </button>
                    </a>
                </div>
              <div className='col-md-6 position-relative text-center'>
                <div className='AreaDetecter position-absolute'>
                    <img src={AreaDetecter} alt='area-detector' />
                </div>
                <model-viewer src="https://d1a370nemizbjq.cloudfront.net/d7179472-65a3-4dab-9238-3cd295f2438a.glb" seamless-poster environment-image="neutral" shadow-intensity="1" autoplay ar ar-modes="webxr scene-viewer quick-look"  disable-zoom camera-controls auto-rotate interaction-prompt-threshold="100"  enable-pan disable-tap  min-camera-orbit="auto 80deg auto" max-camera-orbit="auto 80deg auto" camera-orbit="-337.2deg 95deg 3.878m" field-of-view="30deg">
              </model-viewer>
              </div>
             
          </div>
        </div>

     </section>

     <section className='Collaborators porfolio-slider position-relative'>
        <div className='container'> 
          <h2 className='p-0'>"Explore Our AR/VR Portfolio and See Our Solutions in Action"</h2>
          <p className='p-0 text-center'>Discover the diverse range of clients we serve and the solutions we provide to meet their needs</p>
       
            <div className='portfolio-section'>
                <Portfolio />
            </div>
        </div>
        <div className='bannergredient4'></div>
        <div className='bannergredient3'></div>
     </section>

     <section className='valued-client position-relative'>
        <div className='container'>
            <h2 className='p-0'>Praise from Our Valued Clients</h2>
            <p className='text-center'>Discover the diverse range of clients we serve and the solutions we provide to meet their needs</p>
        </div>

        <Marquee  gradient={false} pauseOnHover={true} speed={75}>
        {arvrclient?.map( client => {
        return(
          <div className='ARVR-client'>
              <div className='client-detail'>
                  <div className='client-feedback'>
                      <p>{client.clientFeedback}</p>
                  </div>
                  <ul className='arvrclient-detail p-0' >
                    <li>
                      <ul>
                        <li className='client-name'>{client.clientName}</li>
                        <li className='client-occupation'>{client.clientOccupation}</li>
                      </ul>
                    </li>
                  </ul>
              </div>
          </div>
          )})} 
        </Marquee>
        <div className='bannergredient3'></div>
     </section>

    
                      
      

      
    </div>
  );
}

export default ARVR;
