
import './App.css';
import { useState,useEffect } from 'react';
import 'react-tabs/style/react-tabs.css';
import Mission from './mission.json';
import Client from "./client.js";
import AboutUsCounter from './AboutUsCounter.json';
import corevalue from './CoreValue.json'
import AboutUsBanner from './assets/aboutusbanner.webp';
import mission from './assets/mission.webp';
import BestSecurity from './assets/best-sequrity.svg';
import RiskMenegment from './assets/risk-manage.svg';
import GrowthRevenue from './assets/Growth-Revenue.svg';
import GreatSupport from './assets/great-support.svg';
import HowWeWork from './assets/webengineering2.svg';
import associates from './assets/associates.webp';
import associates2 from './assets/logo.webp';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// import { PopupButton } from '@typeform/embed-react'
import MetaTags from 'react-meta-tags';
import {  scrollToTop } from "./App";
import { Link } from 'react-router-dom';
function AboutUs() {
    useEffect(() => {
    
        scrollToTop();
     
      }, [])
    const links = [{name:'Mission',img:"./assets/Aboutus/mission.webp"}, {name:'Vision',img:"./assets/Aboutus/vision.webp"}, {name:'Our Team',img:"./assets/Aboutus/ourteam.webp"} ,{name:'Work Culture',img:"./assets/Aboutus/workculture.webp"},{name:'Business Practice',img:"./assets/Aboutus/businessprectice.webp"},{name:'Work Ethics',img:"./assets/Aboutus/workethics.webp"}];
    const links1 = ['Industrious Excellence', 'Innovation', 'Reliability' ,'Client Satisfaction','Team Oriented'];
    const [active, setActive] = useState(null);
  return (
    <div className="App About-Us-main">
     <MetaTags>
           
            <meta name="description" content="We deliver tailored digital solutions to meet your evolving business needs and challenges. Transform and thrive in a competitive industry with our services." />
            <meta name="keyword" content="Mobile App Development, Hire Laravel Developer, Hire Codeigniter, Developer, Hire Javascript Developer, app web developer, developer hire, hire web developer, ui ux web developer, web design and web developer, web developer ui ux, web development services, web development company usa, it services, it consulting, it consulting companies in usa" />
            <meta property="og:title" content="About Us - Web App, Mobile App & AR/VR Development Company" />
            <link rel="canonical" href="https://www.technopus.com/about-us" />
            <title>Technopus IT Services - Rajkot - Gujarat - INDIA | About US</title>
      </MetaTags>
     
      <section className='About-us banner-sec '>
        <div className='container'>
            <h1 className='text-center line-bottom'>About <span className='lastword'>Technopus</span></h1>
            <p className='text-center'>Technopus is a digital solution provider helping organizations address the evolving business needs and challenges in highly competitive industry through digital transformations and services using information technologies tailored to your business requirements.</p>
        </div>
      </section>

      <section className='Real-Time-Journey'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <h2>Get To Know Our Real-Time <span className='lastword'>Journey</span></h2>
                    <ul>
                        <li>Technopus, made its inception in 2009 to deliver top-notch IT development and consulting services for its global client base.</li>
                        <li>We are a young breed of leaders who believe that the treasure of a company is its people and that teamwork is the only way to achieve success.</li>
                        <li>Our team comprises technology lovers and top-notch industry professionals striving to offer a unique, artistic and technical vision to begin with your original idea.</li>
                        <li>We provide solutions that are scalable, reliable and client-focused technology needs adhered to strict deadlines.</li>
                    </ul>
                    <ul>
                    <li>Our commitment to quality, sustainable growth, and total customer satisfaction has made us serve over 230+ clients and accomplish over 100+ projects.</li>
                    <li>Our 45+ skilled IT professionals' clear-cut approach to business domain knowledge, innovative ideas and technology expertise has driven the company in the global industry sphere.</li>
                    <li>Technopus headquarters resides in Rajkot, India and equally appears in the USA to enhance IT development and consulting services.</li>
                </ul>
                </div>
                <div className='col-md-6 position-relative'>
                    <img src={AboutUsBanner} alt={"aboutus"} />
                </div>
               
            </div>
        </div>
      </section>

      <section className='About-counter'>
          <div className='container'>
               <div className='row'>
                    {AboutUsCounter.map( AboutUsCounter => {
                    return(
                    <div className='About-Counter-card col-md-3 col-lg-3 col-sm-6'>
                        <div className='About-Counter-Details'>
                            <img src={AboutUsCounter.image} alt={"aboutus-counter"} />
                            <h3>{AboutUsCounter.count}</h3>
                            <p>{AboutUsCounter.field}</p>
                        </div>
                    </div>
                    )})} 
               </div>
          </div>
      </section>

      <section className='mission-vission'>
          <div className='container'>
             <div className='row position-relative'>
                <div className='col-lg-9 col-md-12 m-auto'>
                      <Tabs>
                            <TabList>
                            {links.map((link) => (
                               
                                
                                <Tab href={`#${link.name}`} 
                                    className={` ${active == link.name && 'm-active'}`}
                                    onClick={() => setActive(link.name)}>{link.name}
                                     <div><img src={link.img} alt={"link"} /></div>
                                </Tab> 
                                ))}
                            </TabList>
                      {Mission.map( Mission => {
                      return(    
                            <TabPanel>
                                <div className='mission-pan'>
                                    <h4>{Mission.title}</h4>
                                   
                                    { Mission.text.map( ps => { 
                                        return(       
                                    <p>{ps}</p>
                                    )})} 
                                     
                                    <ul>
                                    {Mission.list.map( ls => { 
                                        return(
                                        <li>{ls}</li>
                                      )})}    
                                    </ul>
                                </div>   
                            </TabPanel>
                      )})}
                    </Tabs>
                </div>
               
             </div>
          </div>
      </section>

      <section className='Facilites'>
        <div className='container'>
            <h2 className='line-bottom'>We Provide the Best Facilites For <span className='lastword'>Business</span></h2>
            <div className='row'>
                <div className='col-md-6'>
                    <p>At our company, we understand the importance of having a strong online presence for businesses of all sizes. That's why we offer top-notch facilities to help businesses succeed in the digital world. We also have extensive experience supporting WordPress, which is one of the most popular website platforms used by businesses today. Whether you're managing a large website or multiple clients, our easy-to-use control panel and API make it simple to manage your site and access the information you need.</p>
                </div>
                <div className='col-md-6'>
                    <ul>
                        <li>We offer 24/7 expert support to ensure your website runs smoothly.</li>
                        <li>We understand the importance of security and offer advanced measures to keep your site safe.</li>
                        <li>We provide regular backups to ensure your site's data is always protected. </li>
                    </ul>
                </div>
            </div>
        </div>
      </section>

      <section className='about-card'>
           <div className='container'>
              <div className='row'>
                    <div className='col-md-6 col-lg-3 col-sm-6'>
                        <div className='AboutCard text-center'>
                        <img src={BestSecurity} alt={"best sequrity"} loading='lazy'/> 
                        <h5>Best Security</h5>
                        <p>We have seen great successes with everyone companies.</p>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-3 col-sm-6'>
                        <div className='AboutCard text-center'>
                        <img src={RiskMenegment} alt={"Risk Manage"} loading='lazy' /> 
                        <h5>Risk Manage</h5>
                        <p>Every business and industry requires an approach in our Hand.</p>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-3 col-sm-6'>
                        <div className='AboutCard text-center'>
                        <img src={GrowthRevenue} alt={"Growth Revenue"} loading='lazy'/> 
                        <h5>Growth Revenue</h5>
                        <p>You make sure you know how campaign is performing Business</p>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-3 col-sm-6'>
                        <div className='AboutCard text-center'>
                        <img src={GreatSupport} alt={"Great Support"} loading='lazy' /> 
                        <h5>Great Support</h5>
                        <p>To generate highly focused leads ready to purchases our service</p>
                        </div>
                    </div>
              </div>
           </div>
      </section>

      <section className='CoreValues'>
          <div className='container'>
              <h2 className='line-bottom'>Core <span className='lastword'>Values</span></h2>  
              <Tabs>
                            <TabList>
                            {links1.map((link) => (
                                <Tab href={`#${link}`} 
                                    className={` ${active == link && 'm-active'}`}
                                    onClick={() => setActive(link)}>{link}
                                </Tab>  
                                ))}
                            </TabList>
                      {corevalue.map( corevalue => {
                      return(    
                            <TabPanel>
                                <div className='mission-pan'>
                                    <h4>{corevalue.title}</h4>
                                    <p>{corevalue.text}</p>
                                    
                                </div>   
                            </TabPanel>
                      )})}
             </Tabs>
             {corevalue.map( corevalue => {
                      return(  
             <div className='mission-pan mission-pan-mobile'>
                <h4>{corevalue.title}</h4>
                <p>{corevalue.text}</p>              
            </div>
            )})} 
          </div>
      </section>

      <section className='OurAssociates'>
           <div className='container'>
              <h2 className='line-bottom'>Our <span className='lastword'>Associates</span></h2>
              <p>We share a relation with our associates, where we focus on shared opportunities, same vision, collaborative growth and contribute to each other's domain expertise. We follow more conversational & flexible approach and brainstorm sessions on the trending IT digital technologies.</p>
              <div className='row'>
                  <div className='col-md-6 text-center'>
                    <img src={associates} alt={"associates"} style={{maxWidth:"320px"}} loading='lazy' /> 
                  </div>
                  <div className='col-md-6'>
                    <p className='text-start'>
                    Kaprat is a group of enthusiastic people with diverse capabilities and substantial experience in outsourcing Information Technology business model. We believe in embracing the proven and established processes of the industry and continue them with our own philosophy of participation over direction for achieving complete customer satisfaction.</p>
                  </div>
              </div>
              <div className='row'>
                  <div className='col-md-6 text-center'>
                    <img src={associates2} alt={"associates"} style={{maxWidth:"320px"}} loading='lazy' /> 
                  </div>
                  <div className='col-md-6'>
                    <p className='text-start'>
                    Arccus Inc. represents a collective of passionate individuals, each equipped with diverse skills and extensive experience in the outsourcing of Information Technology business models. Our foundation is built on the belief of integrating time-tested and proven industry processes, which we complement with our unique philosophy of prioritizing participation over strict direction. This approach forms the cornerstone of our commitment to attaining absolute customer satisfaction. At Arccus Inc., we thrive on collaboration, valuing the active involvement of each team member in the pursuit of excellence and innovation within the IT outsourcing landscape.</p>
                  </div>
              </div>
           </div>
      </section>
      
      <section className='Tell-Us-About-Your-Project'>
           <div className='container'>
              <h2 className='line-bottom'>Tell Us About Your <span className='lastword'>Project</span></h2>
              <div className='row align-items-center'>
                            <div className='col-md-6'>
                                <img src={ HowWeWork } alt={"HowWeWork"} loading='lazy' />
                            </div>
                            <div className='col-md-6'>
                                <h3>What will happen next?</h3>
                                <ul>
                                    <li>We’ll reach out to you within 24 hours.</li>
                                    <li>We’ll discuss your project and gather your requirements and business objectives, and develop a proposal accordingly.</li>
                                    <li>You can start 15-days risk-free trial with us.</li>
                                </ul>
                                <Link to="/contact-us" className="quote">
                                    Contact Us
                                </Link>
                            </div>
              </div>
           </div>
      </section>

      <section className='client'>
          <div className='container'>
              <h2>Our Happy Clients <span className='lastword'>Experience</span></h2>
              <p className='main-content'>Customer experience during our place, and we share it with you as a reference to believe in us.</p>
              <Client />
          </div>
      </section>

    </div>
  );
}

export default AboutUs;
