import banner from './assets/home.png';
// import { PopupButton } from '@typeform/embed-react'
import bannerbg from './assets/home_bg.png';
import './App.css';
import Client from "./client.js";
import Project from "./project.js"
import  card  from "./card.json";
import React from 'react';
import technology from "./technology.json"
import marquee from "./merquee.json"
import Marquee from "react-fast-marquee";
import { useEffect } from 'react';
import {  scrollToTop } from "./App";
import 'react-tabs/style/react-tabs.css';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
function App() {
  useEffect(() => {
    scrollToTop();
  }, [])
  return (
    <div className="App homepage">
      <MetaTags>
            <meta name="description" content="Technopus is a  Leading app development company with Mobile, web & AR/VR solutions for success. Contact us now!" />
            <meta name="keyword" content="mobile app development agency, application development company" />
            <meta property="og:title" content="Technopus is a Leading app development company in India, USA for custom on-demand delivery solutions. " />
            <link rel="canonical" href="https://www.technopus.com/" />
            <title>Technopus IT Services - Rajkot - Gujarat - INDIA | Home</title>
      </MetaTags>
      <section className='home-banner banner-sec'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-md-6'>
                    <div className='banner-content'>
                        <h1>Enliven your ideas with our Web Development <span className='lastword'>Services</span></h1>
                        <p>Technopus is an <b>Top Web Development</b> Company centered on custom web development. We are a one-stop destination for your every single requisite of digital services. Request your quote from all over the globe and hire your web developer today.</p>
                        <Link to="/contact-us" className="quote">
                        Request A Quote
                        </Link>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='banner-img'>
                    <img src={banner} className="main-banner" alt={"banner"} width={""} height={""} />
                    <img src={bannerbg} className='banner-bg' alt={"banner bg"} width={"auto"} height={"auto"} />
                    </div>
                </div>
            </div>
        </div>
      </section>
      <section className='services'>
        <div className='container'>
            <h2>Our Web Design & Web Development  <span className='lastword'>Services</span></h2>
            <div className='d-flex flex-wrap justify-content-center'>
            {card.map( card => {
                return(
                <div className='  card-grid'>
                  <div className='card  '>
                      <div className='card-icon'>
                        <img src={card.sericon} alt={"card"} width={75} height={75} loading="lazy" />
                      </div>
                      <div className='card-detail'>
                        <ul>
                          <li className='card-title'>{card.sertitle}</li>
                          <li className='card-text'>{card.serabout}</li>
                        </ul>
                      </div>
                  </div>   
                </div>
            )})}     
            </div>
        </div>
      </section>
      <section className='why-choose'>
          <div className='container'>
              <div className='row'>
                  <div className='count-card col-md-6'>
                      <div className='left-main'>
                          <div className='left-counter-card '>
                              <h3>14+</h3>
                              <p>Year in business</p>
                          </div>
                          <div className='left-counter-card '>
                              <h3>12000+</h3>
                              <p>Projects made with total success</p>
                          </div>
                      </div>
                      <div className='right-main'>
                          <div className='right-counter-card '>
                              <h3>45+</h3>
                              <p>Team Members</p>
                          </div>
                      </div>
                  </div>
                  <div className='why-choose-text col-md-6' >
                    <h2>Why Choose <span className='lastword'>Technopus</span></h2>
                      <ul>
                        <li>Commitment to Excellence</li>
                        <li>Continuous Improvisations</li>
                        <li>Thorough understanding of Client's’ Objectives</li>
                        <li>Security and Alliances</li>
                        <li>Responsible and trustworthy</li>
                      </ul>
                      <ul>
                        <li>Cost Effective Development</li>
                        <li>Agile yet Flexible Development</li>
                        <li>Collaborate with you every step of the way</li>
                        <li>Simple Approachability</li>
                        <li>Transparent from start to finish</li>
                      </ul>
                  </div>
              </div>    
          </div>
      </section>
      <section className='client'>
          <div className='container'>
              <h2>Our Happy Clients <span className='lastword'>Experience</span></h2>
              <p className='main-content'>Customer experience during our place, and we share it with you as a reference to believe in us.</p>
              <Client />
          </div>
      </section>
      <section className='industries'>
        <h2>Industries We <span className='lastword'>Serve</span></h2>
        <div className='marquee d-block'>
        
          {marquee.map( (data, ind) => {
            return(
              <Marquee direction={ind === 0 ? "left" : 'right'} gradient={false} pauseOnHover={true}>
              {data.data.map( marquee => {
                  return(
                  <div className='marquee-slider' style={{background:marquee?.color}}>
                      <div className='marquee-slide'>
                          <div className='marquee-img'>
                              <img src={marquee.images} width={"50"} height={"50"} alt="marquee" loading='lazy' />
                          </div>
                          <ul>
                            <li className='industries-name'>{marquee.industries}</li>
                            <li className='about-industries'>{marquee.about}</li>
                          </ul>
                      </div>
                  </div>
                  )})} 
              </Marquee>)})}
        </div>
      </section>
      <section className='technology'>
      <div className='marquee d-block'>
                    <h2>Build Your Web Apps For <span className='lastword'>Any Platform</span></h2>
                      <Marquee  gradient={false} pauseOnHover={true} speed={75}>
                      {technology.map( (tech, ind) => {
                      return(    
                        <div className='technology-slide '>
                          <div className='tech-head'>
                            <h6>{tech.name}</h6>
                          </div>
                          <div className='technology-row'>
                      {tech.tech.map( technology => {
                      return(
                          
                          <div className='technology-pan'>
                              <img src={technology.images} alt={"technology"} loading='lazy'  />
                              <h6>{technology.name}</h6>
                          </div>
                      )})}     
                      </div>
                        </div>
                     
                      )})}
                      </Marquee>
         </div>
      </section>
      <section className='project'>
           <div className='container position-relative'>
              <div className='carosoul-control'>
                        <h2>View Our Featured  Client  <span className='lastword'>Projects</span></h2>
                </div>
                 
                <div className='position-class'>
                  <div className='project-carosoul '>
                    <Project />
                  </div>  
                </div> 
                {/* <p className='text-center mt-5'>
                  <a href='https://works.arccusinc.com' target='blank' className='quote ml-auto'>Show More</a>
                </p> */}
            </div>
      </section>
    </div>
  );
}
export default App;
