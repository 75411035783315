import { Link } from "react-router-dom";
import './App.css';
import Axios from 'axios';
import React, { useState,useEffect } from "react";
import Marquee from "react-fast-marquee";
import expertise from './expertise.json';
import officelocation from './officelocation.json';
import contact from './contact.json';
import { Button } from '@react-email/button';
import ReCAPTCHA from "react-google-recaptcha";
import { Widget } from '@typeform/embed-react'
import MetaTags from 'react-meta-tags';
import {  scrollToTop } from "./App";

function ContactUs() {
 
  useEffect(() => {
    
    scrollToTop();
 
  }, [])
   
  



  return (
    <div className="App Contact-Us-main">
     <MetaTags>
            
            <meta name="description" content="Contact us for Web, Mobile & AR/VR App Development Requirements. Our Team will get back to you with further details. Talk to Us!" />
            <meta name="keyword" content="Mobile App Development, Hire Laravel Developer, Hire Codeigniter Developer, Hire Javascript Developer, hire web developer, ui ux web developer, Web Engineering, Digital Marketing, Game Developing, Web CMS And Portal Development" />
            <meta property="og:title" content="Contact Us - Web, AR/VR & Mobile App Development Company" />
            <link rel="canonical" href="https://www.technopus.com/contact-us" />
            <title>Technopus IT Services - Rajkot - Gujarat - INDIA | Contact US</title>
      </MetaTags>
     
      <section className='Contact-us  banner-sec'>
        <div className='container'>
            <h1 className='text-center line-bottom'>Contact  <span className='lastword'>Us</span> </h1>
            <p className='text-center'><Link to="/">Home</Link> / Contact</p>
        </div>
      </section>

      <section className='contact-us-form'>
        <div className='container'>
           <div className="row justify-content-center">
                <div className="col-4 contact-us-left">
                    <h2>Contact details</h2>
                    <p>Delaying can hinder your progress; we are just a call away.</p>
                    <ul>
                    {contact.map( contact => {
                      return(
                      <li>
                        <ul>
                
                        </ul>
                        <ul>
                          <li><h3>Business Email</h3></li>
                          <li className="contact-detail">
                          <Button href={contact.mail}>
                          {contact.mailid}
                          </Button>               
                          </li>
                          <li className="contact-detail">  
                          <Button href="tel:+919726866367">
                            +91 9726866367
                          </Button>             
                          </li>
                        </ul>
                        <ul>
                          <li><h3>Business Social Media</h3></li>
                          <li className="contact-detail">
                          <Button href='skype:samirshingrakhiya?chat'>
                          Let's Talk on Skype
                          </Button>                       
                          </li>
                          <li className="contact-detail">
                          <Button href='https://www.linkedin.com/in/samir-shingrakhiya-30485318/'>
                          visit Linkedin
                          </Button>                       
                          </li>
                        </ul>
                        <ul>
                          <li><h3>Office Hours</h3></li>
                          <li className="contact-detail">{contact.day}</li>
                          <li className="contact-detail">{contact.time}</li>
                        </ul>
                      </li>
                      )})} 
                    </ul>
                </div>
                {/* <div className="col-lg-8 contact-us-right">
                    
                
                    <Widget id="JVCr9mG0" style={{width: '100%', height: '500px'}} className="my-form" hideHeaders={true}
                      hideFooter={true} />
                      <iframe title="contact" style={{width: '100%', height: '1062px'}} className="my-form" src="https://tally.so/embed/3xDX7J?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"></iframe>
                </div> */}
           </div>
        </div>
      </section>

      <section className='get-in-touch'>
          <div className='container'>
               <h4>Get in Touch</h4>
               <h2>Our Office Locations</h2>
               <div className="row location-row">
               {officelocation.map( officelocation => {
                      return(
                    <div className="location col-lg-4 col-sm-12 col-md-6">
                        <img src={officelocation.image}  alt={"location"} loading='lazy' />
                        <h5>{officelocation.location}</h5>
                        <p>{officelocation.address}</p>
                    </div>
                    )})} 
               </div>
          </div>
      </section>

    

      <section className='expertise'>
        <div className='container'>
           <div className="row align-items-center">
                <div className="col-md-2 col-sm-3">
                    <h4>EXPERTISE</h4>
                </div>
                <div className="technology col-md-10 col-sm-9">
                <Marquee gradient={false} pauseOnHover={true}>
                {expertise.map( expertise => {
                      return(
                  <div className=''>
                      <img src={expertise.image} alt={"expertise"} loading='lazy' />
                  </div>
                  )})} 
              </Marquee>
                </div>
           </div>
        </div>
      </section>

     

    </div>
  );
}

export default ContactUs;
